<template>
  <div class="login">
    <loader :active="loaderActive" />
    <template>
      <form class="loginForm">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="10">
              <v-card class="elevation-6 mt-10">
                <v-row>
                  <v-col cols="12" md="6">
                    <div style="padding-top: 100px;"></div>
                    <v-card-text class="mt-12">
                      <h3 class="text-center">Login into your account</h3>
                      <br />
                      <h6 class="text-center  grey--text "></h6>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <p class="error" v-if="user.authError">
                            {{ user.authError }}
                          </p>
                          <v-text-field
                            v-model.trim="username"
                            :error-messages="usernameErrors"
                            label="Username"
                            required
                            @input="$v.username.$touch()"
                            @blur="$v.username.$touch()"
                            type="text"
                          />
                          <v-text-field
                            v-model="password"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :type="showPassword ? 'text' : 'password'"
                            :error-messages="passwordErrors"
                            label="Password"
                            required
                            @click:append="showPassword = !showPassword"
                            @input="$v.password.$touch()"
                            @blur="$v.password.$touch()"
                          />
                          <v-row>
                            <v-col cols="12" sm="7">
                              <!-- <v-checkbox

                        label="Remember Me"
                        class="mt-n1"
                        color="primary"
                      > </v-checkbox> -->
                            </v-col>
                            <v-col cols="12" sm="5">
                              <!-- <span class="caption primary--text">Forgot password</span> -->
                              <br />
                            </v-col>
                          </v-row>

                          <v-btn color="primary" @click="submit" dark block tile
                            ><h4>Login</h4></v-btn
                          >
                          <div
                            class="d-flex  justify-space-between align-center mx-10 mb-16"
                          >
                            <v-spacer></v-spacer>
                            <br />
                            <v-spacer></v-spacer>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="6" class="primary rounded-bl-xl">
                    <div
                      style="  text-align: center; padding: 120px 0px 200px;"
                    >
                      <v-img
                        src="@/assets/logoWhite.png"
                        alt="logo"
                        contain
                        color="white"
                        height="200"
                      ></v-img>
                      <v-card-text class="white--text">
                        <h1 class="text-center font-weight-regular">Kredwise</h1>
                        <br />
                        <br />
                        <h4 class="text-center font-weight-regular">Kontrive Technologies</h4>
                        <br/>
                      </v-card-text>
                      <div class="white--text">
                        <h5 class="text-center font-weight-regular">&copy;
                          {{ new Date().getFullYear() }}
                        </h5>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </template>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import store from "../../store";
import loaderMixin from "../../mixins/loader";
export default {
  name: "Login",
  components: {
    Loader: () => import("../../components/Loader"),
  },
  mixins: [validationMixin, loaderMixin],
  validations: {
    username: { required, email },
    password: { required, maxLength: maxLength(30), minLength: minLength(6) },
  },
  data: () => ({
    username: null,
    password: "",
    showPassword: false,
  }),
  mounted() {
    document.title = "Login | Kredwise";
    if (store.state.user.isLoggedIn) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.showLoader();
        await store.dispatch("user/login", {
          email: this.username,
          password: this.password,
        });
        if (this.user.isLoggedIn) {
          this.hideLoader();
          this.$router.push("/dashboard");
        }
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
          this.hideLoader();
        }, 500);
      }
    },
    clear() {
      this.$v.$reset();
      this.username = "";
      this.password = "";
    },
  },

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      !this.$v.username.required && errors.push("Username is required.");
      !this.$v.username.email &&
        errors.push("Please enter a valid email address");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength &&
        errors.push("Password must be at most 30 characters long");
      !this.$v.password.minLength &&
        errors.push("Password must be more than 6 characters long");
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
    ...mapState(["user"]),
  },
};
</script>
<style lang="scss" scoped>
// .login {
//   min-width: 400px;
//   width: 20%;
//   margin: 250px auto;
// }
// .loginForm {
//   margin-top: 40px;
// }
.error {
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
}
#logInBtn {
  margin-top: 15px;
}
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
