import API from "../../API.js";
import { REQUEST_METHODS } from "../../configs/";
export default {
  state: {
    requestError: null,
    customers: [],
  },
  getters: {},
  mutations: {
    updateCustomerName(state, value) {
      state.id = value.toString().trim();
    },
    updateRequestError(state, value) {
      state.requestError = value;
    },
    updateCustomers(state, value) {
      state.customers = value;
    },
    addCustomer(state, value) {
      state.customers.data.push(value);
    },
    updateCustomer(state, value) {
      const customerIndex = state.customers.data.findIndex(
        (customer) => customer.id === value.id
      );
      state.customers.data.splice(customerIndex, 1, value);
    },
    deleteCustomer(state, value) {
      const customerIndex = state.customers.data.findIndex(
        (customer) => customer.id === value.id
      );
      state.customers.data.splice(customerIndex, 1);
    },
  },
  actions: {
    async fetchCustomers({ commit }) {
      await API(REQUEST_METHODS.GET, "/customers?limit=10000")
        .then(( response ) => {
           console.log('CUSTOMERS FETCHED ---->',response);
          commit("updateCustomers", response, { module: "customer" });
        })
        .catch((err) => {
          console.log(err);
          // add it to customers error message
          commit("updateRequestError", err.message, { module: "customer" });
        });
    },
    async createCustomer({ commit }, item) {
      commit("updateRequestError", null, { module: "customer" });
      await API(REQUEST_METHODS.POST, "/customers", item)
        .then(({ createdCustomer }) => {
          commit("addCustomer", createdCustomer, { module: "customer" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "customer" });
        });
    },
    async updateCustomer({ commit }, item) {
      commit("updateRequestError", null, { module: "customer" });
      await API(REQUEST_METHODS.PUT, `/customers/${item.id}`, {
        username: item.newCustomer,
        password: item.password,
      })
        .then(({ updatedCustomer }) => {
          commit("updateCustomer", updatedCustomer, { module: "customer" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "customer" });
        });
    },
    async deleteCustomer({ commit }, id) {
      commit("updateRequestError", null, { module: "customer" });
      await API(REQUEST_METHODS.DELETE, `/customers/${id}`)
        .then(({ deletedCustomer }) => {
          commit("deleteCustomer", deletedCustomer, { module: "customer" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateRequestError", err.message, { module: "customer" });
        });
    },
  },
  namespaced: true,
};
