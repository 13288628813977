import { BASE_API_URL, ENC_KY } from "./configs/";
import Store from "./store";
const decrypt = (salt, encoded) => {
  if (encoded == null) {
    return;
  }
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};
const API = async function (method, uri, data) {
  var accessTkn = decrypt(ENC_KY, sessionStorage.getItem("tka"));
  var refreshTkn = decrypt(ENC_KY, sessionStorage.getItem("ren"));
  if (accessTkn && typeof accessTkn === "string") {
    const jwt = require("jsonwebtoken");
    const decodedToken = jwt.decode(accessTkn);
    var valid = decodedToken && decodedToken.exp * 1000 > new Date().getTime();
    if (!valid) {
      await fetch(`${BASE_API_URL}/token/refresh`, {
        body: JSON.stringify({
          refresh_token: refreshTkn,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessTkn,
        },
        method: "POST",
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res.success || res.success == undefined) {
            Store.dispatch("user/logout");
            const error = new Error(res.message);
            error.errorCode = res.errorCode;
            throw error;
          }
          Store.dispatch("user/setAsLoggedIn", res.data);
        });
    }
    accessTkn = decrypt(ENC_KY, sessionStorage.getItem("tka"));
    refreshTkn = decrypt(ENC_KY, sessionStorage.getItem("ren"));
  }
  const API_URL = `${BASE_API_URL}${uri}`;
  const response = await fetch(API_URL, {
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessTkn,
    },
    method: method || "GET",
  })
    .then(function (res) {
      if (!res.ok) {
        //console.dir(res);
        // Store.dispatch("user/logout");
        const error = new Error(res.statusText);
        error.errorCode = res.status;
        throw error;
      }
      return res.json();
    })
    .catch((res) => {
      // Store.dispatch("user/logout");
      const error = new Error(res.message);
      error.errorCode = res.errorCode;
      throw error;
    });
  return response;
};

export default API;
