import API from "../../API.js";
import { REQUEST_METHODS, ENC_KY } from "../../configs/";
const encrypt = (salt, text) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
};
const toggleLogin = ({ data, isLoggedIn, commit }) => {
  commit("updateIsLoggedIn", isLoggedIn, { module: "user" });
  commit("updateAuthError", null, { module: "user" });
  sessionStorage.setItem("iLI", isLoggedIn);
  if (isLoggedIn == false) {
    sessionStorage.removeItem("iLI");
    sessionStorage.removeItem("tka");
    sessionStorage.removeItem("ren");
    return;
  }
  sessionStorage.setItem("tka", encrypt(ENC_KY, data.access_token));
  sessionStorage.setItem("ren", encrypt(ENC_KY, data.refresh_token));
  const jwt = require("jsonwebtoken");
	const decodedToken = jwt.decode(data.access_token);
	let name = decodedToken.username;
  commit("updateUsername", name, { module: "user" });
};
export default {
  state: {
    isAdmin: false,
    isLoggedIn: false,
    username: "",
    authError: null,
    users: [],
  },
  getters: {},
  mutations: {
    updateUsername(state, value) {
      state.username = value;
    },
    updateIsLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    updateIsAdmin(state, value) {
      state.isAdmin = value;
    },
    updateAuthError(state, value) {
      state.authError = value;
    },
    updateUsers(state, value) {
      state.users = value;
    },
    addUser(state, value) {
      state.users.data.push(value);
    },
    updateUser(state, value) {
      const userIndex = state.users.data.findIndex(
        (user) => user.username === value.username
      );
      state.users.data.splice(userIndex, 1, value);
    },
    deleteUser(state, value) {
      const userIndex = state.users.data.findIndex(
        (user) => user.username === value.username
      );
      state.users.data.splice(userIndex, 1);
    },
  },
  actions: {
    async login({ commit }, credentials) {
      await API(REQUEST_METHODS.POST, "/login", credentials)
        .then(({ data }) =>
          toggleLogin({
            data,
            isLoggedIn: true,
            commit,
          })
        )
        .catch((err) => {
          const message = err;
          toggleLogin({ data: {}, isLoggedIn: false, commit });
          commit("updateAuthError", message, { module: "user" });
        });
    },
    async logout({ commit }) {
      const message = "Session terminated.";
      //toggleLogin({ data: {}, isLoggedIn: false, commit });
      commit("updateAuthError", message, { module: "user" });
      await API(REQUEST_METHODS.POST, "/logout")
        .then(() =>
        toggleLogin({ isLoggedIn: false, commit })
        )
        .catch((err) => {
          console.log("ERROR ---> ",err)
          if (!err.success) {
            const message = "You are looged out";
            toggleLogin({ isLoggedIn: false, commit });
            commit("updateAuthError", message, { module: "user" });
          }
        });
    },
    setAsLoggedIn({ commit }, data) {
      var isLoggedIn = true;
      if(data.refresh_token=="" || data.access_token==""){
        isLoggedIn = false
      }
       toggleLogin({ data, isLoggedIn: isLoggedIn, commit });
    },

    async fetchUsers({ commit }) {
      await API(REQUEST_METHODS.GET, "/users?limit=10000&offset=0")
        .then((response) => {
          commit("updateUsers", response, { module: "user" });
        })
        .catch((err) => {
          console.log(err);
          // add it to users error message
          commit("updateAuthError", err.message, { module: "user" });
        });
    },
    async createUser({ commit }, item) {
      commit("updateAuthError", null, { module: "user" });
      await API(REQUEST_METHODS.POST, "/user", item)
        .then(({ createdUser }) => {
          commit("addUser", createdUser, { module: "user" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateAuthError", err.message, { module: "user" });
        });
    },
    async updateUser({ commit }, item) {
      commit("updateAuthError", null, { module: "user" });
      await API(REQUEST_METHODS.PUT, `/user/${item.id}`, {
        username: item.newUsername,
        password: item.password,
      })
        .then(({ updatedUser }) => {
          commit("updateUser", updatedUser, { module: "user" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateAuthError", err.message, { module: "user" });
        });
    },
    async deleteUser({ commit }, id) {
      commit("updateAuthError", null, { module: "user" });
      await API(REQUEST_METHODS.DELETE, `/user/${id}`)
        .then(({ deletedUser }) => {
          commit("deleteUser", deletedUser, { module: "user" });
        })
        .catch((err) => {
          console.error(err);
          commit("updateAuthError", err.message, { module: "user" });
        });
    },
  },
  namespaced: true,
};
