import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.module';
import organization from './modules/organization.module';
import customer from './modules/customer.module';
import transaction from './modules/transaction.module';
import dashboard from './modules/dashboard.module';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://images.unsplash.com/photo-1546788774-2bb47f590283?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {

  },
  modules: {
    user,
    organization,
    customer,
    transaction,
    dashboard,
  },
})
//rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)
