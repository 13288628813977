import API from "../../API.js";
import { REQUEST_METHODS } from "../../configs/";
export default {
  state: {
    requestError: null,
    dashboardData: [],
  },
  getters: {},
  mutations: {
    updateRequestError(state, value) {
      state.requestError = value;
    },
    updateDashboardData(state, value) {
      state.dashboardData = value;
    },
  },
  actions: {
    async fetchDashboardData({ commit }) {
      await API(REQUEST_METHODS.GET, "/dashboard?limit=10000")
        .then(( response ) => {
           console.log('DASHBOARD DATA FETCHED ---->',response);
          commit("updateDashboardData", response, { module: "dashboard" });///
        })
        .catch((err) => {
          console.log(err);
          // add it to dashboard error message
          commit("updateRequestError", err.message, { module: "dashboard" });///
        });
    },
  },
  namespaced: true,
};
