import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "../i18n/";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

const lightTheme = {
  primary: "#10477C",
  secondary: "#2093FA",
  accent: "#2093FA",
  info: "#0CAEFF",
  lightgray: "#A8A8A8",
  customblack: "#000000",
};
const darkTheme = {
  primary: "#10477C",
  secondary: "#2093FA",
  accent: "#2093FA",
  info: "#0CAEFF",
  lightgray: "#FFFFFF",
  customblack: "#FFFFFF",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: darkTheme,
      light: lightTheme,
    },
    dark: true,
  },
});
