<template>
  <v-app>
    <loader :active="loaderActive" />
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { ENC_KY } from "./configs/";
import store from "./store";
import loaderMixin from "./mixins/loader";
const decrypt = (salt, encoded) => {
  if (encoded == null) {
    return;
  }
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return encoded
    .match(/.{1,2}/g)
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar)
    .map((charCode) => String.fromCharCode(charCode))
    .join("");
};
export default {
  name: "App",
  mixins: [loaderMixin],
  components: {
    DashboardCoreAppBar: () =>
      import("./views/dashboard/components/core/AppBar"),
    DashboardCoreDrawer: () =>
      import("./views/dashboard/components/core/Drawer"),
    DashboardCoreView: () => import("./views/dashboard/components/core/View"),
    Loader: () => import("./components/Loader"),
  },
  data: () => ({
    expandOnHover: false,
    drawer: false,
    refresh_token: "",
  }),
  computed: {
    ...mapState(["user"]),
  },
  async mounted() {
    if (sessionStorage.getItem("iLI")) {
      this.showLoader();
      await store.dispatch("user/setAsLoggedIn", {
        access_token: decrypt(ENC_KY, sessionStorage.getItem("tka")),
        refresh_token: decrypt(ENC_KY, sessionStorage.getItem("ren")),
      });
      this.hideLoader();
    }
  },
};
</script>
<style>
@import "./css/main.css";
</style>
